.paper .page {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 3mm 4mm;
    grid-template-areas:
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . .";
}

.paper .page .label {
    border: .01px solid #fff;
    width: 100%;
    height: 100%;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 39.6mm;
    height: 39.6mm;
    /* padding: 1mm 0!important; */
    /* position: relative; */
}

.paper .page .label img {
    width: 34mm;
    height: 34mm;
}

.paper .page .label span {
    font-size: 8px;
    /* position: absolute;
    bottom: 0;
    left: 25%;
    right: 25%; */
}

.paper .page .label_unique_id {
    border: .01px solid #fff;
    width: 100%;
    height: 100%;
    /* text-align: center; */
    display: flex;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    width: 19.8mm;
    height: 19.8mm;
    /* padding: 1mm 0!important; */
    position: relative;
}

.paper .page .label_unique_id img {
    width: 17mm;
    height: 17mm;
    position: absolute;
    top: 2px;
    left: 5px;
}

.paper .page .label_unique_id span {
    font-size: 8px;
    position: absolute;
    bottom: 0;
    left: 25%;
    right: 25%;
}

@media print {
    .page {
        /* display: block; */
        page-break-after: always;
        page-break-inside: avoid;
        /* border: 1px solid #fff; */
        /* position: relative; */
    }
}