.ant-layout-sider.ant-layout-sider-dark.dashboard-siderbar {
    width: 100% !important;
    max-width: 220px !important;
    overflow: hidden;

    .sidebar-logo {
        position: absolute;
        background-color: #fff;
        padding: .5rem 1rem;
        bottom: 1.5rem;
        border-radius: 0 6rem 6rem 0;
        width: 80%;

        img {
            width: 90%;
        }
    }

    .sidebar-menu {
        height: 80%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 .5rem;

        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            // border-radius: 10px;
            background-color: #69AF36;
        }

        &::-webkit-scrollbar
        {
            width: 5px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
            // border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #196EC2;
        }



        .ant-menu-item {
            border-radius: 7px;
            padding: 0 1rem!important;

            &::after {
                border-right: 3px solid transparent;
            }
        }

        .sidebar-menu-content {
            margin-bottom: 200px;
        }
    }

    
}
  