.steps-content {
  min-height: 200px;
  margin-top: 5px;
  padding-top: 10px;
  text-align: center;
  border: 2px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.ant-typography-copy {
  margin-top: -5px;
  margin-left: 8px;
}
.ant-tooltip-content .ant-tooltip-inner {
  color: #fff!important;
}
.product-card {
  background: linear-gradient(45deg, #2B33B2, #1486C9);
  border-radius: 10px!important;
  color: #fff;
}
.product-title {
  /* background: linear-gradient(45deg, #84BF47, #4B9E23);
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}
.product-bonus {
  /* background: linear-gradient(45deg, #16A085, #F4D03F);
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-size: 20px;
  color: #F4D03F;
}
.amount {
  background: linear-gradient(45deg, #1486C9, #2B33B2);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.withdraw {
  background: linear-gradient(45deg, #e43a15, #e65245);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.income {
  background: linear-gradient(45deg, #11998e, #0dd65a);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bold {
  font-weight: 600;
}