.paper .page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    width: 74.5mm;
    border: 1px solid #333;
}   

.paper .page .container_qr_code {
    padding: .25rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    font-weight: 700;
}

.paper .page .container_qr_code .qr_code_location {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
}

.paper .page .container_qr_code .qr_code_location img {
    width: 236px;
    height: 236px;
}

.paper .page .unique_code_location {
    width: 236px;
    display: flex;
    justify-content: center;
}

@media print {
    .page {
        /* display: block; */
        page-break-after: always;
        page-break-inside: avoid;
        /* border: 1px solid #fff; */
        /* position: relative; */
    }
}