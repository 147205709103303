$colorWhite01: #FFFF;
$colorWhite02: #F0F2F5;

$colorBlue01: #003366;
$colorBlue02: #1890FF;
$colorBlue03: #0272ae;
$colorBlue04: #21BADF;

$colorPurple01: #BF24AD;

$colorGrey01: #e6e6e6;

$colorOrange01: #FA8C16;

$colorYellow: #DCDF34;

$colorRed01: #EC6D70;

$colorGreen: #00C899;