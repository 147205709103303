#print-by-opim  .ant-form-item {
    margin-bottom: 1rem !important;
}
#print-by-opim .ant-input {
    max-width: 345px;
}
.address {
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    width: 100%;
    min-height: 110px;
    padding: 16px;
    color: #333333;
    max-width: 345px;
    cursor: pointer;
    font-size: 12px;
}
.address .address-title {
    font-weight: 600;
    font-size: 14px;
}
.address .address-description {
    background-color: #f7f7f7;
    border-left: 3px solid #1890ff;
}
.receipt {
    /* background: linear-gradient(158.65deg, #21BADF -21.77%, #293AB4 140.03%); */
    background-image: url('src/assets/images/bg-card.png');
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    padding: 8px 24px;
    color: #fff !important;
    margin-bottom: 1em;
}
.receipt .address {
    border: none;
    padding: 0;
    border-radius: 0;
    min-height: auto;
    padding: 16px 0;
    color: #fff;
    max-width: 345px;
    cursor: pointer;
    font-size: 12px;
}
.receipt .address .address-title {
    font-size: 14px;
    color: #fff;
}
.receipt  .address .address-description {
    font-weight: 400;
    background-color: #f7f7f7;
    border-left: 3px solid #293AB4;
    color: #293AB4;
}