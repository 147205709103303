.modal-create--roles {
  // background-color: red;

  .ant-modal-content {
    
    .ant-modal-header {
      
      .ant-modal-title {
        font-size: 12px;
      }
    }
    .ant-modal-body {
      label,
      input,
      textarea,
      button,
      svg,
      .ant-select {
        font-size: 12px;
      }
    }

  }
}