
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
  margin-bottom: 1.5rem;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.upload-certificates .ant-upload {
  width: 100% !important;

  button {
    width: 100% !important;
  }
}