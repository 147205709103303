  .container-create-user {
    padding: 2rem;
  
    .big-title {
      font-size: 14px;
      font-weight: 500;
      padding: 5px 1rem;
      background-color: $colorBlue01;
      border-radius: 10px 10px 10px 0;
      max-width: 20%;
      text-align: center;
      color: $colorWhite01;
      margin: 0;
    }

    svg, button, label, input, textarea {
      font-size: 12px;
    }

    input {
      padding-left: 8px !important;
    }

    .ant-select
    .ant-select-selector {
      
      .ant-select-selection-placeholder {
        font-size: 12px;
      }
      
      .ant-select-selection-item,
      .ant-select-item-option-content {
        font-size: 12px;
      }
    }
  }
