.list-address {
    border: 1px solid #E0E0E0;
    padding: 16px;
    border-radius: 7px;
    margin-bottom: 16px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.list-address.active {
    border: 1px solid rgb(43 51 178);
    background-color: #293AB41A;
}
h2.address-title {
    font-size: 18px;
    font-weight: 600;
    margin: 16px 0 0;
}
h3.address-title {
    font-size: 16px;
    font-weight: 600;
}
.leaflet-container { 
    height: 350px; 
    margin-bottom: 24px;
}
.address-modal {
    border-radius: 8px !important;
}
.popup-marker {
    cursor: pointer;
}