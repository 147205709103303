.paper .page {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: (16, 1fr);
    gap: 5.3mm 4mm;
}

.paper .page .label {
    border: 1px solid #333;
    width: 38mm;
    height: 38mm;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paper .label img {
    width: 34mm;
}

.paper .label span {
    font-size: .5rem;
    display: flex;
    justify-content: center;
}

.paper .page:nth-child(n) {
    margin-top: 1mm;
}

@media print {
    .page {
        /* display: block; */
        page-break-after: always;
        page-break-inside: avoid;
        /* border: 1px solid #fff; */
        /* position: relative; */
    }
}