.container-login {
  width: 100%;
  margin: 0 auto;

  .card-login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: linear-gradient(-135deg, $colorPurple01, $colorBlue01);

    .wrap-login {
      width: 960px;
      background: $colorWhite01;
      border-radius: 10px;
      overflow: hidden;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 5rem 8rem 0rem 3rem;

      .login-pic {
        width: 50%;
        display: flex;
        justify-content: center;

        img {
          height: 80%;
          max-width: 100%;
        }
      }

      .login-form {
        width: 290px;

        .login-form-title {
          font-size: 14px;
          color: $colorBlue02;
          line-height: 1.2;
          span {
            font-size: 18px;
            font-weight: 700;
          }
          width: 100%;
          display: block;
          padding-bottom: 34px;
        }

        .wrap-input {
          position: relative;
          width: 100%;
          z-index: 1;
          margin-bottom: 10px;
        }


        input {
          background-color: $colorGrey01;
          padding-left: 1rem;
          font-size: 12px;
        }

        .form-input {
          font-family: Poppins-Medium;
          font-size: 15px;
          line-height: 1.5;

          width: 100%;
          background: $colorGrey01;
          height: 50px;
          border-radius: 25px;
          padding: 0 30px;
        }

        .form-button-login {
          font-size: 12px;
          width: 100%;
          background-color: $colorBlue02;
          color: $colorWhite01;
          border-radius: 5rem;
          height: 2.5rem;

          &:hover {
            background-color: #1d5093;
            transition: 0 1s;
          }
        }

        a {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .container-login {
    .card-login {

      .wrap-login {
        display: flex;
        justify-content: center;
        padding: 10rem 0;

        .login-pic {
          display: none;
        }
      }
    }
  }
}

