.container-setting-qr {
  background-color: red;

}
.modal-setting-qr {
  .ant-modal-body
  .ant-form
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input {
    button { 
      font-size: 12px;
      width: 100% !important;
    }
  }
}