  .container-dashboard,
  .container-products,
  .container-roles {
    .ant-table {
      thead tr th {
        font-size: 12px;
      }
      tbody tr td, button {
        font-size: 12px;
      }
      .text-user,
      .text-admin,
      .text-gold_tenant,
      .text-free_tenant {
        border-radius: 3px;
        color: $colorWhite01;
        padding: 2px 0;
        margin: 0 2rem;
        text-align: center;
      }
      .text-user {
        background-color: $colorBlue02;
      }
      .text-gold_tenant {
        background-color: $colorOrange01;
      }
      .text-free_tenant {
        background-color: $colorPurple01;
      }
      .text-admin {
        background-color: $colorGrey01;
      }
      button {
        padding: 0 1rem;
        height: 1.7rem;
        border: 0;
        color: $colorWhite01;
      }
      .button-update {
        background-color: $colorOrange01;
      }
      .button-detail {
        background-color: $colorBlue04;
      }
      .button-upload {
        background-color: $colorGreen;
      }
      .button-delete {
        background-color: $colorRed01;
      }
      .button-edit {
        background-color: $colorYellow;
      }
      .button-print {
        background-color: $colorGreen;
      }
      .button-download {
        background-color: $colorBlue03;
      }
    }
  }