.ant-tooltip-inner {
    color: #212529!important;
    padding: 10px 16px;
}
.download-item {
    font-size: 12px;
    cursor: pointer;
}
.download-item:hover{
    color: #1890FF;
}