.amount {
    background: linear-gradient(45deg, #1486C9, #2B33B2);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .withdraw {
    background: linear-gradient(45deg, #e43a15, #e65245);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .income {
    background: linear-gradient(45deg, #11998e, #0dd65a);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .bold {
    font-weight: 600;
  }