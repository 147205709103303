.scan-page-container {
    max-width: 500px;
    margin: 0px auto;
}

.scan-claim-popup {
    position: fixed;
    z-index: 10;
    bottom: 0px;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    padding: 0 15px 36px;
}

.claim-btn {
    margin-left: auto;
    background: linear-gradient(96.72deg, #3D0099 -8.42%, #4F55F2 89.92%) !important;
}
.unique-btn {
    background-color: #68D644!important;
}

.claim-popup-btn {
    /* margin-right: 3rem; */
    font-size: 15px;
    font-weight: 800;
    padding: 6px 20px;
    height: auto!important;
    border: none!important;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}
.modal-claim {
    max-width: 95%!important;
    top: 50px;
}
.coupon-form {
    display: flex;
    width: 100%;
}
.spin-height {
    height: 100vh!important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.bg-original {
    height: 90px;
    position: absolute;
    bottom: 0px;
    right: 100px;
}
.certificate-description {
    font-size: 12px!important;
}

.carousel .slick-dots {
    position: relative;
    margin-bottom: 32px;
    margin-top: -24px;
}
.carousel .slick-dots .slick-active button::before {
    color: #57D139;
    opacity: 1;
}
.slick-dots li button:focus:before, .slick-dots li button:hover:before {
    color: #57D139;
}
.carousel .slick-dots li button:before {
    /* color: #57D139!important; */
    font-size: 10px;
}
.carousel-item {
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin-bottom: 10px;
    /* max-width: 345px; */
}
.slick-slide:not(:last-child) {
    padding-right: 14px;
}
.carousel-image {
    width: 100%;
    height: auto;
    max-height: 100px;
    vertical-align: middle;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.carousel-text {
    margin: 12px 16px;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
}
.carousel h5 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
}
.carousel-image-wrapp .ant-image-mask {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.socials img {
    height: 50px;
    padding: 10px;
    margin: 1rem 0;
    cursor: pointer;
}

.customColumn {
    flex-direction: row!important;
    flex-wrap: wrap!important;
}

@media only screen and (min-width: 575px) {
    .customColumn {
        flex-direction: column!important;
    }
}