.paper .page {
    margin: auto;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5.8mm 40mm;
}

.paper .page .label {
    /* border: .01px solid #222; */
    width: 100%;
    height: 100%;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 19.8mm;
    height: 19.8mm;
    /* padding: 1mm 0!important; */
    /* position: relative; */
}

.paper .page .label img {
    width: 17mm;
    height: 17mm;
    /* position: absolute;
    top: 2px;
    left: 5px; */
}

.paper .page .label span {
    font-size: 8px;
    /* position: absolute;
    bottom: 0;
    left: 25%;
    right: 25%; */
}

.paper .page .label_unique_id {
    /* border: .01px solid #222; */
    width: 100%;
    height: 100%;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 19.8mm;
    height: 22mm;
    gap: -2px;
    padding: .5mm 0!important;
}

.paper .page .label_unique_id img {
    width: 17mm;
    height: 17mm;
    /* position: absolute;
    top: 2px;
    left: 5px; */
}

.paper .page .label_unique_id span {
    font-size: 8px;
}

@media print {
    .page {
        /* display: block; */
        page-break-after: always;
        page-break-inside: avoid;
        /* border: 1px solid #fff; */
        /* position: relative; */
    }
}