@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import './variables/color.scss';
@import './screen-setting-qr/screen-index.scss';

@import './sceeen-credentials/screen-login.scss';
@import './sceeen-credentials/scree-register.scss';
@import './screen-prducts/screen-detail-product.scss';
@import './screen-dashboard/screen-create.scss';
@import './screen-dashboard/screen-index.scss';
@import './screen-prducts/screen-index.scss';
@import './generate-qr/styles.scss';

@import './__partials/margin.scss';

@import './__components/buttons.scss';
@import './__components/table.scss';
@import './__components/header.scss';
@import './__components/form.scss';
@import './__components/cards.scss';

@import './__partials/sidebar.scss';

@import './screen-roles/screen-create.scss';

* {
  font-family: 'Poppins', sans-serif;
}

.container-layout {

  .title-profile {
    font-size: 14px;
    text-transform: capitalize;
    color: $colorWhite01;
    position: relative;
    margin: 0;
    font-weight: bold;
    letter-spacing: .5px;
    margin-bottom: .4rem;

    cursor: pointer;
  }

  .ant-layout-sider {
    color: $colorBlue01;
    background: linear-gradient(180deg, #1486C9, #2B33B2);

    .img-sider {
      position: absolute;
      top: 0;
      right: 0;
    }

    .sidebar-opim-wrap {
      position: absolute;
      bottom: 5rem;
      z-index: 100;

      img: {}
    }

    .credit {
      color: white;
      // position: absolute;
      // top: 4.3rem;
      font-size: 12px;
    }

    .ant-menu {
      // background: $colorBlue01;
      background: transparent;

      svg {
        // color: $colorBlue02;
        color: white;

        &::hover {
          // color: $colorOrange01
          color: white;
        }
      }

      .ant-menu-item {
        a {
          // color: $colorWhite01;
          color: white !important;
          font-size: 12px;
        }

        .anticon {
          transition-duration: 0.3s !important;
        }
      }

      .ant-menu-item-selected {
        span {
          a {
            color: $colorBlue02 !important;
          }

          svg {
            color: $colorBlue02;
          }
        }
      }

      .ant-menu-item-active {
        span {

          a,
          svg {
            color: $colorBlue01 !important;
          }
        }
      }
    }
  }

  .ant-layout.site-layout {
    margin-left: 220px !important;
  }

  .site-layout-background {
    min-height: 100vh;
  }

  .ant-page-header {

    .ant-page-header-heading-left {
      margin: 0;
      width: 100% !important;

      .ant-page-header-heading-title {
        p {
          font-size: 12px;
          margin: 0;
        }

        .ant-select {
          font-size: 12px;
        }
      }
    }

    input {
      font-size: 12px;

      &::placeholder {
        font-size: 12px;
      }
    }

    button {
      font-size: 12px;
    }

    button.dashboard-btn {
      background: linear-gradient(45deg, #1486C9, #2B33B2) !important;

      .anticon-export {
        svg {
          color: white;
        }
      }
    }

    .ant-page-header-heading-title {
      font-size: 14px;
      font-weight: normal;
    }
  }

}

.ant-menu-title-content,
.ant-menu-submenu-arrow {
  font-size: 12px;
  color: $colorBlue02 !important;
}

.sidebar-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 0;

  .ant-menu-item {
    font-size: 12px;
    padding: 0 15px !important;
  }
}

.ant-picker-input input {
  font-size: 12px !important;
  height: 1.6rem !important;
}

.anticon {
  vertical-align: 0 !important;
}

.ant-switch-checked {
  background: linear-gradient(45deg, #1486C9, #2B33B2) !important;
}

.ant-table {
  p {
    margin: 0;
  }

  // .ant-table-thead {
  //   background: linear-gradient(45deg, #1486C9, #2B33B2) !important;
  // }
}

.ant-input,
.ant-input-lg,
.ant-select-selector {
  font-size: 12px !important;

  &::placeholder {
    font-size: 12px;
  }
}

.container-common {
  background-color: $colorWhite01 !important;
  min-height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-nav {
  display: flex;
  align-items: center;

  a {
    font-size: 12px !important;
  }

  .button {
    border: 0;
    padding: 0 1rem;
    font-size: 12px;
    border-radius: 5px;
  }

  .button-registration {
    font-size: 12px;
    color: $colorWhite01;
    background: linear-gradient(45deg, #1486C9, #2B33B2) !important;

    & :hover,
    & :active {
      color: $colorWhite01 !important;
    }
  }

  .button-signin {
    font-size: 12px;
    color: $colorWhite01;
    background-color: #3E8217 !important;

    & :hover,
    & :active {
      color: $colorWhite01 !important;
    }
  }
}

.webview-component {

  .ant-card {
    min-width: 40%;
    // background-image: url('../images/illustration-certificate.jpg');
    background-repeat: no-repeat, repeat;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    text-align: center;

    .bg-left {
      position: absolute;
      top: 0;
      left: 0;
    }

    .bg-right {
      position: absolute;
      top: 0;
      right: 0;
    }

    .bg-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    .description-items {
      justify-content: space-between;
      padding: 0 10rem;
      font-weight: bold;
      overflow: hidden;
    }
  }

  .big-title-sertifikat {
    font-weight: normal;
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
    letter-spacing: 5px;

    span {
      font-weight: normal;
      letter-spacing: 3px;
      font-size: 44px;
      font-weight: bold;
    }
  }

  .footer-description {
    margin-top: 1rem;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    max-width: 30rem;
  }
}

.container-empty {
  padding: 1rem;
  justify-content: center;
}

.container-login {
  .ant-input-affix-wrapper-lg {
    padding: 12.5px 15px;

    input {
      padding-left: .5rem !important;
    }
  }

  button {
    background-color: #46389E;
    color: $colorWhite01;
    border-radius: 5px;
    height: 40px !important;
  }
}

@media only screen and (max-width: 768px) {
  .webview-component {
    .ant-card {
      width: 100%;
      text-align: center;

      .description-items {
        justify-content: space-between;
        padding: 0 1rem;
        font-size: 12px;
        font-weight: bold;
        overflow: hidden;
      }
    }

    .footer-description {
      font-size: 10px;
    }

    .big-title-sertifikat {
      span {
        font-size: 30px;
      }
    }
  }
}


// RIFUSID - LANDINGPAGE

.brand-logo {
  background-color: #fff;
  width: 80%;
  padding: .5rem 1rem;
  position: absolute;
  // bottom: 3rem;
  border-radius: 0 6rem 6rem 0;

  img {
    width: 80%;
  }
}

.mockup-image {
  transform: scale(1.2);
}

.hero-content {
  padding-bottom: 7rem!important;
}

.google-play-btn {
  color: #2B33B2;
}

.background-img:before {
    position: absolute;
    content: '';
    background-image: linear-gradient(270deg, #1486C9, #2B33B2);   
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.solid-btn {
  background: linear-gradient(45deg, #1486C9, #2B33B2) !important;
  border: #1373ab;
}
.solid-btn:hover {
  color: #fff;
}
.popular-price,
.single-pricing-pack:hover {
  border-top: 4px solid #2B33B2 !important;
}

.outline-btn {
  color: #2B33B2;
  border: 2px solid #2B33B2;
}
.outline-btn:hover {
  background: linear-gradient(45deg, #1486C9, #2B33B2) !important;
}
.single-client {
  width: auto;
}
.owl-theme.clients-carousel img {
    height: 101px;
    width: auto;
}
.footer-top {
  padding-top: 10rem!important;
  padding-bottom: 10rem!important;
}

.pt-10 {
  padding-top: 10rem;
}

@media screen and (max-width: 767px) {
  .mockup-image {
    margin-top: 3rem;
  }  
  .navbar .navbar-toggler {
    margin-right: 2rem!important;
  }

  .ml-s-3 {
    margin-left: 0px;
  }
}