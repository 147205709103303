.date-wrapper {
    background-color: #f4f4f4;
    font-size: 12px
}
.date-menu {
    padding: 1.2rem 1rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.date-menu span {
    color: #707070;
}
.date-menu.active {
    color: #1890FF; 
    background-color: #fff;
}
.date-menu:hover {
    color: #1890FF; 
}
.date-expand {
    padding: 1.2rem 1rem;
    background-color: #fff;
}