.paper .page {
    /* border: 1px solid #333; */
    /* width: 200mm; */
    /* padding: 0mm 1mm; */
    /* height: 140mm; */
    margin: auto;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center
}

.paper .page .label {
    width: 336px;
    height: 169px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* ext-align: center; */
    border: 1px solid #222;
    margin: 1.3mm 2mm;
    padding: 1mm 1mm;
    /* flex-direction: column; */
}

.paper .page .label .qrcode {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.paper .page .label .desc {
    font-size: .6rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2mm;
}

/* Tepi Atas */


/* .paper .label {
    margin: 0mm 1mm 1mm 0mm;
} */
/* 
.paper .label:nth-child(2),
.paper .label:nth-child(3),
.paper .label:nth-child(4) {
    margin: 0mm 1mm 1mm 1mm;
}

.paper .label:nth-child(5) {
    margin: 0mm 1mm 1mm 1mm;
}

.paper .label:nth-child(6) {
    margin: 0mm 1mm 1mm 0mm;
}
.paper .label:nth-child(11) {
    margin: 0mm 1mm 1mm 0mm;
}
.paper .label:nth-child(16) {
    margin: 0mm 1mm 1mm 0mm;
}
.paper .label:nth-child(21) {
    margin: 0mm 1mm 1mm 0mm;
}
.paper .label:nth-child(26) {
    margin: 0mm 1mm 1mm 0mm;
}
.paper .label:nth-child(31) {
    margin: 0mm 1mm 1mm 0mm;
}
.paper .label:nth-child(36) {
    margin: 0mm 1mm 1mm 0mm;
} */



.paper .label img {
    width: 17mm;
    height: 17mm;
}

.paper .label .qrcode span {
    /* display: none; */
    font-size: 8px;
}

.paper .page:nth-child(n) {
    margin-top: 2mm;
}

@media print {
    .page {
        /* display: block; */
        page-break-after: always;
        page-break-inside: avoid;
        /* position: relative; */
    }
}