.paper .page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.paper .page .container_qr_code {
    border: 1px solid #333;
    padding: .125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
}

.paper .page .container_qr_code img {
    width: 34mm;
    height: 34mm;
}

@media print {
    .page {
        /* display: block; */
        page-break-after: always;
        page-break-inside: avoid;
        /* border: 1px solid #fff; */
        /* position: relative; */
    }
}