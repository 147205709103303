.paper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paper .page {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 6mm 4mm;
  grid-template-areas:
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . ."
    ". . . . .";
}

.paper .page .label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.paper .page .label .qrcode {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.paper .page .label .desc {
  font-size: 0.6rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2mm;
}

.paper .label img {
  width: 17mm;
}

.paper .label .qrcode span {
  font-size: 8px;
}

@media print {
  .page {
    page-break-after: always;
    page-break-inside: avoid;
    margin-top: 0.51in;
    margin-left: 0.41in;
    margin-right: 0.19in;
  }
}
