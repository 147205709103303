
.ant-table {

    border-radius: 8px!important;
    overflow: hidden;

    .ant-table-thead {
        background: #fff!important;
        tr {
            th {
                background: #fff!important;
                // color: #fff!important;
                font-weight: bold!important;
                text-transform: uppercase!important;
            }
        }
    }
}