.matrik {
    background: #f0f5ff;
    padding: 1rem 1rem 13px;
    border-radius: 8px;
    cursor: pointer;
}
.matrikWrapp {
    display: flex; 
    align-items: center; 
    justify-content: space-between;
    margin-bottom: 1rem;
}
.tooltipIcon {
    font-size: 12px;
    color: #999;
    margin-left: 6px;
}
.checkedIcon {
    font-size: 18px;
}
.checkedIcon svg path {
    fill: #1486C9;
}
.subtitle {
    font-size: 12px;
    color: #999;
    display: flex;
    justify-content: space-between;
}
.percent {
    display: flex;
    align-items: center;
}
.up {
    display: flex;
    align-items: center;
    color: #389e0d;
}
.down {
    display: flex;
    align-items: center;
    color: #f5222d;
}