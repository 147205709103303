.container-register {
  width: 100%;
  margin: 0 auto;
  
  .card-register {
    width: 100%;  
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: linear-gradient(-135deg, $colorPurple01, $colorBlue01);

    .wrap-register {
      width: 960px;
      background: $colorWhite01;
      border-radius: 10px;
      overflow: hidden;
  
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5rem 3rem;
      
      .register-form {
        width: 30vw;

        .register-form-title {
          font-size: 20px;
          font-weight: bold;
        }

        .ant-input-affix-wrapper, .ant-select-selector {
          border-radius: 40px;
          background: $colorWhite02;
          font-size: 12px;
          padding: 0 18px;
        }
        .ant-input-affix-wrapper {
          padding: 8px 18px;
        }
  
        input {
          padding-left: .5rem;
          font-size: 12px;
          background: $colorWhite02;
        }

        .form-button-register {
          font-size: 12px;
          width: 100%;
          background-color: $colorBlue01;
          color: $colorWhite01;
          border-radius: 5rem;
          height: 2.5rem;

          &:hover {
            background-color: $colorBlue02;
            transition: 0 1s;
          }
        }

        a {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .container-register {
    .card-register {

      .wrap-register {
        display: flex;
        justify-content: center;
        padding: 2rem 0;

        .login-pic {
          display: none;
        }

        .register-form {
          width: 90%;
        }
      }
    }
  }
}