.paper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paper .page {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 3mm 5mm;
  grid-template-areas:
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . .";
}

.paper .page .label {
  border: 0.01px solid #fff;
  width: 100%;
  height: 100%;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 19.8mm;
  height: 19.8mm;
  /* padding: 1mm 0!important; */
  /* position: relative; */
}

.paper .page .label img {
  width: 17mm;
  height: 17mm;
  /* position: absolute;
    top: 2px;
    left: 5px; */
}

.paper .page .label span {
  font-size: 8px;
  /* position: absolute;
    bottom: 0;
    left: 25%;
    right: 25%; */
}

.paper .page .label_unique_id {
  border: 0.01px solid #fff;
  width: 100%;
  height: 100%;
  /* text-align: center; */
  display: flex;
  /* flex-direction: column;
    justify-content: center;
    align-items: center; */
  width: 19.8mm;
  height: 19.8mm;
  /* padding: 1mm 0!important; */
  position: relative;
}

.paper .page .label_unique_id img {
  width: 17mm;
  height: 17mm;
  position: absolute;
  top: 2px;
  left: 5px;
}

.paper .page .label_unique_id span {
  font-size: 8px;
  position: absolute;
  bottom: 0;
  left: 25%;
  right: 25%;
}

@media print {
  .page {
    page-break-after: always;
    page-break-inside: avoid;
    margin-top: 0.51in;
    margin-left: 0.41in;
    margin-right: 0.19in;
  }
}
